import VueRouter from 'vue-router';
import routes from './routes';
import { TokenStorage } from "../modules/token-storage";
import { store } from "@/store";

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
  linkActiveClass: 'active',
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition
    } else {
      return { x: 0, y: 0 }
    }
  }
});


router.beforeEach((to, from, next) => {
  TokenStorage.isAuthenticated().then(isAuthenticated => {
    if (isAuthenticated) {
      const userRole = store.getters.getRole;
      console.log('the tole',userRole);
      if (to.name === "Login" || to.name === "Register") {
        next({ name: userRole === "electrician" ? "ElectricianDashboard" : "Dashboard" });
      } else if (to.name === "ElectricianDashboard" && userRole !== "electrician") {
        next({ name: "Dashboard" });
      } else if (to.name === "Dashboard" && userRole === "electrician") {
        next({ name: "ElectricianDashboard" });
      } else if (to.name === "ElectricianSettings" && userRole !== "electrician") {
        next({ name: "Dashboard" });
      } else if (to.name === "Settings" && userRole === "electrician") {
        next({ name: "ElectricianSettings" });
      } else {
        next();
      }
    } else {
      if (to.name === "Dashboard" || to.name === "ElectricianDashboard") {
        next({ name: "Login" });
      } else {
        next();
      }
    }
  });
});
export default router;

